import React, { useEffect } from 'react';
import FullPageCarousel from '../Components/Carousel/FullPageCarousel';
import ImageCard from '../Components/ImageCard/ImageCard';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './PageCss/HomePage.css';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import CenterStage from '../Components/CenterStage/CenterStage';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook


import imgHose from '../assets/hose.jpg';
import imgMetalHose from '../assets/metal-hose.jpg';
import imgIndustrialHose from '../assets/industrial-hose.jpg';
import imgHighPressureHose from '../assets/high-pressure-hose.jpg'
import qualitySticker from '../assets/QualityLogo.png';
import glob from '../assets/GlobLogo.png';
import hose from '../assets/HoseLogo.png';
import isoLogo from '../assets/ISO-Logo.png';
import imgCarton from '../assets/Hansa-Flex_Kartons_Shop.png';
import imgDigitilization from '../assets/DigilisationImg.jpg'
import imgLogistics from '../assets/LogisticsImg.jpg';
import imgTrainingCourse from '../assets/TrainingCourseImg.jpg';
import imgIree from '../assets/news-events/iree.jpg';
import imgGarry from '../assets/news-events/garry.jpg';
import imgGCC from '../assets/news-events/gcc.jpg';
import imgTree from '../assets/news-events/tree.jpg';
import imgBauma from '../assets/news-events/bauma.jpg';
import imgMachine from '../assets/machine.jpg';
import imgHighPressurePipe from '../assets/high-pressure-pipe.jpg';
import ContactBubble from '../Components/ContactBubble/ContactBubble';
import imgIFP from '../assets/IFP1.jpg';
import imgMHP from '../assets/MHP1.jpg';
import imgSHP from '../assets/SHP1.jpg';
import imgTubing from '../assets/tubing.jpg';
import imgAdapters from '../assets/adapters.jpg';
import imgIndustries from '../assets/Industries.png';
import imgXcodeManagement from '../assets/xcode-management.jpg';
import imgTubingAssemblies from '../assets/tube-assemblies.jpg';
import imgEasyOrder from '../assets/easy-order.jpg';
import imgMobileService from '../assets/mobile-hydraulic-service.jpg';
import imgIndustryMob from "../assets/imgIndustriesMob.png";
import imgIndustryLap from '../assets/imgIndustriesLap.png';
import ingIndustries from '../assets/industries.jpg';

import Promoter from '../Components/Promoter/Promoter';
import FeatureCardCarousel from '../Components/FeaturesCardCarousal/FeatureCardCarousal'

import stage1 from '../assets/stage-1.jpg';
import HFTab from '../Components/HfTabs/HfTabs';
import FeaturesCard from '../Components/FeaturesCard/FeaturesCard';
import ShowcaseCarousel from '../Components/ShowcaseCarousel/ShowcaseCarousel';
import HfFooter from '../Components/HfFooter/HfFooter'
import ImageText from '../Components/ImageText/ImageText';
import imgGoetheVisit from '../assets/news-events/goethe.jpg';

import AOS from 'aos';
import 'aos/dist/aos.css';
import NumberCounter from '../Components/NumberCounter';
import imgTag from '../assets/tag.svg'
import imgArrowUp from '../assets/arrow-up-short.svg'
import imgBarcode from '../assets/upc.svg'
import NumberGrid from '../Components/NumberGrid/NumberGrid';
import ListBoard from '../Components/ListBoard/ListBoard';



function HomePage() {

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 320, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  const carouselItems = [
    {
      imgSrc: imgIFP,
      title: 'IFP - Insert Ferrule and Fittings Unit',
      description: 'Our state-of-the-art manufacturing facility is dedicated to crafting premium Insert & Ferrules products.',
      link: '/ifp'
    },
    {
      imgSrc: imgMHP,
      title: 'MHP - Metal Hose Production Unit',
      description: 'The journey of HANSA-FLEX India commenced with this unit initially focusing on serial hose production. Over time, our operations expanded',
      link: '/mhp'
    },
    {
      imgSrc: imgSHP,
      title: 'SHP - Serial Hose Production Unit',
      description: "Welcome to HANSA-FLEX India's Serial Hose Production. Our journey began humbly in 2018 with a small unit, which you can explore further.",
      link: '/shp'
    }
  ];


  const featuresData = [
    {
      title: "Highest quality",
      description: "All products are subject to comprehensive testing within the HANSA‑FLEX quality assurance system.",
      image: qualitySticker
    },
    {
      title: "Worldwide leader",
      description: "HANSA‑FLEX is the system provider for fluid technology products and services.",
      image: glob
    },
    {
      title: "Diverse portfolio",
      description: "We offer a comprehensive product range, with everything available online as well.",
      image: hose
    },
    {
      title: "ISO Standards Approved",
      description: "We are Certified! We promise safety and quality.",
      image: isoLogo
    }
    // Add more objects for additional FeaturesCard data
  ];

  //promoter content

  const promoterTitle = 'PLACE YOUR ORDER TODAY!';
  const promoterChecklistItems = [
    'The full HANSA‑FLEX product range with more than 80,000 articles',
    'Category-specific filter parameters enable quick navigation',
    'Current availability shown in real-time for better planning certainty'
  ];
  const promoterButtonLabels = ['CONTACT US', 'FIND OUT MORE !'];

  //**

  const contactBubbleData = {
    image: imgDigitilization,
    title: 'Digital services for straightforward product identification',
    description: 'Make the most of faster and more error-free data transfer using state-of-the-art scanner technology or EDI interfaces within a user-friendly application environment.',
    listItems: [
      'Online shop and My.HANSA-FLEX customer portal',
      'Scanner solutions to determine your product needs',
      'Master and material data transferred electronically'
    ],
    buttonName: "Learn more"
  }

  // hf tabs content
  const imageTextData = [
    {
      image: imgDigitilization,
      title: 'Digital services for straightforward product identification',
      description: 'Make the most of faster and more error-free data transfer using state-of-the-art scanner technology or EDI interfaces within a user-friendly application environment.',
      listItems: [
        'Online shop and My.HANSA-FLEX customer portal',
        'Scanner solutions to determine your product needs',
        'Master and material data transferred electronically'
      ],
      buttonName: "Learn more"
    },
    {
      image: imgLogistics,
      title: 'Logistics Services',
      description: 'Efficiently manage your supply chain with our comprehensive logistics solutions tailored to meet your specific needs.',
      listItems: [
        'Streamlined inventory management',
        'Optimized delivery scheduling',
        'Real-time tracking and reporting'
      ],
      buttonName: "Discover more"
    },
    {
      image: imgTrainingCourse,
      title: 'Training Courses',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at mi at dui lobortis sollicitudin. Curabitur finibus cursus hendrerit. Donec nulla lorem, ultricies quis ipsum vel, consequat aliquet eros. Proin magna libero, mattis sed feugiat nec, malesuada sed lacus. Nam posuere, purus a ornare tincidunt, sapien ligula lacinia eros, eu eleifend leo risus ut diam. Aenean volutpat ante ut pellentesque porta. Donec ut augue vestibulum, pretium massa et, accumsan magna..',

    },
  ];
  //  

  //image text data for heighlight points
  const highlightImgTxtData = {
    image: imgMachine,
    title: 'Leading system partner for hydraulics',
    description: 'HANSA‑FLEX offers a 360° fluid technology service for the planning, operation and optimisation of mobile and stationary hydraulic systems. An extensive product range naturally complements our comprehensive service portfolio – from highly efficient and flexible repair and installation, to complex engineering and consulting services – with our close-knit branch network, specialised manufacturing plants, digital service offerings and innovative logistics solutions tying it all together.',

    buttonName: "Learn more"
  }

  const highlightImgTxtData2 = {
    image: imgHighPressurePipe,
    title: 'The premium hose line for high pressures',
    description: "The HD 700 LL premium hose line from HANSA-FLEX represents a pinnacle of engineering excellence, meticulously crafted to excel even in the most demanding of conditions. This hose line is the result of extensive research, development, and testing, aimed at delivering unparalleled performance and durability.",

    buttonName: "Learn more"
  }
  const counters = [
    { imageSrc: imgBarcode, targetNumber: 13000, description: 'Available Parts In USA' },
    { imageSrc: imgArrowUp, targetNumber: 40000, description: 'Hoses Built in USA Yearly' },
    { imageSrc: imgTag, targetNumber: 40976, description: 'X-Code Recorded in USA' },
    // Add more counter objects as needed
  ];

  //hall of fame
  const numbers = ['?', '?', '?'];
  const subtitles = ['Available parts in USA', 'Hoses built yearly in USA', 'X-CODES recorded in USA'];

  const googleReviewsLink = "https://www.google.com/search?q=HANSAFLEX+usa&rlz=1C1GCEU_enIN1077IN1078&oq=HANSAFLEX+usa&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIPCAEQLhgKGK8BGMcBGIAEMgYIAhBFGDsyBggDEEUYOzIGCAQQRRg7MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEIMTEzM2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x8640dab1ffa084fd:0xa576320281165a1b,1,,,,";


  return (
    <div>
      <FullPageCarousel />



      <SectionHeader
        title="Explore our Products"
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHose} learnMoreLink="/hydraulic-hoses" title="HANSA-FLEX provides premium quality hoses" desc="The HANSA‑FLEX range includes hoses for hydraulics and pneumatics as well as for the transport of chemicals, food, air, water and abrasive media. In addition to standard hoses, we also supply suction and positive pressure hoses, suitable accessories and preassembled hose lines manufactured to your individual requirements. As a full-service provider, we also support our customers with installation, maintenance and inspection." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgTubing} learnMoreLink="/pipebends" title="Hydraulic tubing and Assemblies" desc="As a system partner, we can proudly announce that HANSA-FLEX Hydraulics USA Inc. offers design and production of bent tubes of all kinds – quickly, with attention to detail and precision. Constricted areas and spaces sometimes the only way is by means of a tube assembly. Our HANSA-FLEX team in Concord..." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgAdapters} learnMoreLink="/hydraulic-connection-technology" title="Hydraulic Connectors and controls" desc="HANSA-FLEX is the place to go for adapters, fittings, connections, couplings and more..." />
          </Col>
          {/* <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHighPressureHose} learnMoreLink="/ext-high-pfm-hoses" title="EXTREMELY HIGH-PRESSURE HOSES FOR APPLICATIONS UP TO 3,200 BAR" desc="HANSA‑FLEX extremely high-pressure hoses are always the first choice in systems where elevated pressures prevail. Indeed, they may have to cope with pressures up to 4,000 bar in the oil and gas industry, water jetting, hydraulic applications and the automotive sector. HANSA‑FLEX supplies extremely high-pressure hoses specially customised to suit your requirements and delivered as ready-for-installation hose lines." />
          </Col> */}
        </Row>
      </Container>

      <div>
        <SectionHeader
          title="Discover our Services"
        />

        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
              <ImageCard image={imgHose} learnMoreLink="/hydraulic-hoses" title="Custom Hydraulic Hoses" desc=" Our custom hydraulic hoses are meticulously crafted to your specifications, ensuring peak performance and durability. Track each hose effortlessly with our innovative X-Code Manager system, simplifying inventory management. Simplify ordering with Easy-Order Scan and optimize your workflow with our comprehensive kitting solutions." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
              <ImageCard image={imgMobileService} learnMoreLink="/mobile-rapid-hydraulic-service" title="Mobile Hydraulic Service" desc="As a system partner, we can proudly announce that HANSA-FLEX Hydraulics USA Inc. offers design and production of bent tubes of all kinds – quickly, with attention to detail and precision. Constricted areas and spaces sometimes the only way is by means of a tube assembly. Our HANSA-FLEX team in Concord..." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
              <ImageCard image={imgTubingAssemblies} learnMoreLink="/pipelines" title="Tailored Tube Assemblies" desc="Experience precision and reliability with our custom tube assemblies, engineered to meet your unique requirements. Our expert team ensures quality and consistency, delivering solutions that exceed expectations. Streamline your operations and enhance efficiency with our tailored tube assemblies." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
              <ImageCard image={imgEasyOrder} learnMoreLink="/scannersolutions" title="Easy-Order Scan " desc="Simplify your procurement process with Easy-Order Scan, enabling quick and hassle-free ordering of hydraulic hoses and components. Scan, order, and receive your products with ease, reducing downtime and increasing productivity. Experience the convenience of seamless ordering today." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
              <ImageCard image={imgXcodeManagement} learnMoreLink="/x-code-manager" title="X-CODE Manager" desc="Harness the power of X-Code Manager to effortlessly track and manage your hydraulic hose inventory. Each hose is assigned a unique X-Code, providing real-time visibility and control. Say goodbye to inventory headaches and hello to streamlined operations with our cutting-edge inventory management solutions." />
            </Col>

          </Row>
        </Container>

      </div>

      {/* <div>
        <SectionHeader
          title="Explore our units!"
          paragraph="We have our units in Pune, which provide extensive range of products!"
        />
        <ShowcaseCarousel carouselItems={carouselItems} descDisplay={isMobile ? 'none' : 'block'} />
      </div>
      <br /><br /><br /> */}

      <div className='div-gray center-all-items'>
        <SectionHeader
          title="Premium Hydraulics & Pneumatics Solutions"
          subtitle="Select from more than 80,000 articles and always find the right product for your needs. We stock everything from A-Z."
        />
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            btnLabel1={"Find Out More !"}
            btnLabel1Link={"https://shop.hansa-flex.us/en_US/"}
            imageUrl={imgCarton}
          />
          // TODO: contact us button
        )}
      </div>

      <div>
        {/* <SectionHeader
          title="News and Events" />
        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={8} data-aos="fade-up" ><ImageCard image={imgIree} learnMoreLink={'/iree'} title="HANSA-FLEX at IREE" desc={"HANSA-FLEX India is thrilled to have participated in the 15th edition of the International Railway Equipment Exhibition (IREE), which took place from October 12 to 14, 2023, at Pragati Maidan, New Delhi. IREE stands as Asia's largest platform dedicated to the Rail Transportation Sector, and this event was organized by the Confederation of Indian Industry (CII) in collaboration with the Ministry of Railways, Government of India."} /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgBauma} learnMoreLink={'/bauma'} title="Bauma Conexpo India 2023 " desc="The 6th edition of international trade fair Bauma Conexpo India 2023, construction machinery event was held in Greater Noida, from Jan 31st -3rd Feb, 2023. " /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgTree} learnMoreLink={'/tree-plantation'} title="Tree Plantation on Environment Day" desc="HANSA-FLEX India Pvt Ltd proudly celebrates Environment Day by organizing a tree plantation event, acknowledging the importance of environmental conservation and sustainability. The event is scheduled to take place on the 5th of June 2023, from 9:00 am to 11:00 am" /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgGarry} learnMoreLink={'/product-training'} title="Product training session taken by Gary Howes" desc="Join us for an extensive training session on Hose Assembly, Fittings, and Welding Products presented by Gary Howes. Taking place from the 4th to the 14th of July 2023 at HANSA-FLEX (IFP) INDIA PVT LTD, this comprehensive training program will cover a wide range of topics essential for professionals in the industry." /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgGoetheVisit} learnMoreLink={'/goethe-event'} title="Empowering Students: Insights into Career Opportunities and Language Skills" desc="Join us for an extensive training session on Hose Assembly, Fittings, and Welding Products presented by Gary Howes. Taking place from the 4th to the 14th of July 2023 at HANSA-FLEX (IFP) INDIA PVT LTD, this comprehensive training program will cover a wide range of topics essential for professionals in the industry." /></Col>
          </Row>

        </Container> */}

      </div>

      {/* <div>
        <SectionHeader
          title={"HANSA-FLEX Hydraulics USA Inc."} /><br />
        <NumberGrid numbers={numbers} subtitles={subtitles} /><br /><br />
      </div> */}

      <br /><br /><br />

      <div className="text-center div-gray">
        <SectionHeader
          title={"Check out our reviews on google!"}
          paragraph={"Curious about what others have to say about us? Take a moment to explore our Google reviews and discover why our customers rave about their experiences with us. "} />
        <br />
        <Button variant="primary" href={googleReviewsLink} target="_blank" className="btn-google" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>View our reviews on Google</Button>

        <br /><br /><br />
      </div>


      <br /><br /><br />

      <div>
        <SectionHeader title={"Industries Served"} />
        <div className="home-imageContainer">
          {isMobile ? <>
            <img src={ingIndustries} alt="Description of the image" className='home-img' />

          </> : <>
            <img src={ingIndustries} alt="Description of the image" className='home-img' />

          </>}
        </div>

        <br /><br /><br /><br />

      </div>


    </div>

  );
}

export default HomePage;
